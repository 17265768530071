import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/shared/link';
import Deutsch from 'images/flag-deutch.inline.svg';
import English from 'images/flag-english.inline.svg';

import styles from './languages.module.scss';

const cx = classNames.bind(styles);

const Languages = ({ current }) => (
  <ul className={cx('wrapper')}>
    {current === 'de' && (
      <li>
        <Link to="/">
          <English />
          <span>English</span>
        </Link>
      </li>
    )}
    {current === 'en' && (
      <li>
        <Link to="/de/">
          <Deutsch />
          <span>Deutsch</span>
        </Link>
      </li>
    )}
  </ul>
);

Languages.propTypes = {
  current: PropTypes.string.isRequired,
};

export default Languages;
