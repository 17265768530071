import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/shared/link';

import styles from './menu.module.scss';

const cx = classNames.bind(styles);

const Menu = ({ language, items }) => (
  <nav className={cx('wrapper')}>
    <ul className={cx('list')}>
      {items.map(({ label, path }, index) => (
        <li className={cx('item')} key={index}>
          <Link className={cx('link')} to={path}>
            {label[language]}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.objectOf(PropTypes.string).isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
};

export default Menu;
