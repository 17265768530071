import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/shared/link';
import Logo from 'images/logo-footer.inline.svg';

import styles from './footer.module.scss';
import reward1 from './images/reward-1.svg';
import reward2 from './images/reward-2.svg';

const cx = classNames.bind(styles);

const Footer = ({ menuItems, copyright, language }) => {
  const currentYear = new Date().getFullYear();
  const copyrightText = `${copyright} © ${currentYear}`;

  const getLabel = (label) => (typeof label === 'object' ? label[language] : label);
  return (
    <footer className={cx('wrapper')}>
      <div className={cx('container', 'inner')}>
        <div className={cx('left')}>
          <div className={cx('left-head')}>
            <Link className={cx('logo-wrapper')} to="/">
              <Logo className={cx('logo')} />
            </Link>

            <ul className={cx('menu')}>
              {menuItems.map(({ label, path }, index) => (
                <li key={index}>
                  <Link className={cx('link')} to={path}>
                    {getLabel(label)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className={cx('copyright')}>
            <span className={cx('copyright-text')}>{copyrightText}</span>

            <span className={cx('copyright-design')}>
              Design made by
              <Link to="https://pixelpoint.io/" target="_blank">
                Pixel Point
              </Link>
            </span>
          </div>
        </div>

        <div className={cx('right')}>
          <div className={cx('right-item')}>
            <img src={reward1} alt="" height="154" width="284" loading="lazy" />
          </div>
          <div className={cx('right-item')}>
            <img src={reward2} alt="" height="180" width="284" loading="lazy" />
          </div>
        </div>

        <div className={cx('copyright-mobile')}>
          <span className={cx('copyright-text')}>{copyrightText}</span>

          <span className={cx('copyright-design')}>
            Design made by
            <Link to="https://pixelpoint.io/" target="_blank">
              Pixel Point
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  menuItems: [
    {
      label: 'Benefits',
      path: '#benefits',
    },
    {
      label: 'Partners',
      path: '#partners',
    },
    {
      label: 'Services',
      path: '#services',
    },
    {
      label: 'Blog',
      path: '/',
    },
    {
      label: 'Features',
      path: '#features',
    },
    {
      label: { en: 'Imprint', de: 'Impressum' },
      path: '/de/imprint',
    },
    {
      label: 'Platform',
      path: '#platform',
    },
    {
      label: { en: 'Data protection', de: 'Datenschutzerklärung' },
      path: '/de/data-protection',
    },
  ],
  copyright: 'LIMEBIRD GmbH',
};

Footer.propTypes = {
  menuItems: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  copyright: PropTypes.string,
  language: PropTypes.string.isRequired,
};

export default Footer;
