import PropTypes from 'prop-types';
import React from 'react';

import Footer from 'components/shared/footer';
import Header from 'components/shared/header';
import SEO from 'components/shared/seo';

const MainLayout = ({ language, children, menuItems }) => (
  <>
    <SEO />
    <Header language={language} menuItems={menuItems} />

    <main>{children}</main>
    <Footer language={language} />
  </>
);

MainLayout.defaultProps = {
  language: 'en',
  menuItems: undefined,
};

MainLayout.propTypes = {
  language: PropTypes.oneOf(['en', 'de']),
  children: PropTypes.node.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.objectOf(PropTypes.string).isRequired,
      path: PropTypes.string,
    })
  ),
};

export default MainLayout;
