import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Link from 'components/shared/link';
import Logo from 'images/logo.inline.svg';

import styles from './header.module.scss';
import Languages from './languages';
import Menu from './menu/menu';

const cx = classNames.bind(styles);

const getIndexURL = (language) => (language === 'de' ? '/de/' : '/');

const Header = ({ language, menuItems }) => (
  <header className={cx('wrapper')}>
    <div className={cx('container', 'inner')}>
      <Link className={cx('logo-wrapper')} to={getIndexURL(language)}>
        <Logo className={cx('logo')} />
      </Link>
      <Menu language={language} items={menuItems} />
      <div className={cx('right')}>
        <Languages current={language} />
        <Button className={cx('button')} size="md" to="mailto:info@limebird.io">
          Contact us
        </Button>
      </div>
    </div>
  </header>
);

Header.defaultProps = {
  menuItems: [
    {
      label: {
        en: 'Benefits',
        de: 'Vorteile',
      },
      path: '#benefits',
    },
    {
      label: {
        en: 'Services',
        de: 'Services',
      },
      path: '#services',
    },
    {
      label: {
        en: 'Features',
        de: 'Features',
      },
      path: '#features',
    },
    {
      label: {
        en: 'Platform',
        de: 'Plattform',
      },
      path: '#platform',
    },
    {
      label: {
        en: 'Partners',
        de: 'Partner',
      },
      path: '#partners',
    },
    {
      label: {
        en: 'Blog',
        de: 'Blog',
      },
      path: '/',
    },
  ],
};

Header.propTypes = {
  language: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.objectOf(PropTypes.string).isRequired,
      path: PropTypes.string,
    })
  ),
};

export default Header;
